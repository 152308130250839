import cnomk from "./../../Assets/img/omg-white.svg";

const Footer = ({ handleModal }) => {
  const date = new Date();
  const year = date.getFullYear();
  const hFooter = "Mentions légales";
  const bFooter = [
    "Identité de l'entreprise : dénomination sociale, forme juridique, adresse du siège social et montant du capital social.",
    "Numéro d'immatriculation au RCS : RCS : Registre du commerce et des sociétés ou RM",
    "Mail et numéro de téléphone pour contacter votre entreprise",
    "Numéro d'identification à la TVA",
    "Identité de l'hébergeur : Entreprise en charge de stocker sur ses serveurs les données du site internet du site : nom ou dénomination sociale, adresse et numéro de téléphone",
    "Si vous exercez une activité réglementée et soumise à autorisation (pharmacie ou débit de boissons, par exemple) : nom et adresse de l'autorité qui a délivré l'autorisation",
  ];

  return (
    <footer className="max-w-screen-xl mx-auto py-20 lg:py-24">
      <div className="flex items-center justify-center flex-col px-8">
        <div className="text-gray-100">Nom du cabinet de Kinésitérapie</div>
        <div
          onClick={() => handleModal(hFooter, bFooter)}
          className="my-3 text-lg hover:text-gray-500 transition duration-300 cursor-pointer"
        >
          <p>Mentions légales</p>
        </div>
        <div>
          <a href="https://www.ordremk.fr/">
            <img
              src={cnomk}
              alt="Conseil National de l'ordre des Masseurs-Kinésitérapeutes"
            />
          </a>
        </div>
        <div className="text-center mt-10 font-medium tracking-wide text-sm text-gray-600">
          &copy; {year}, Cabinet de kinésithérapie.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const Pictures = () => (
  <div className="container mx-auto px-5 w-screen">
    <div className="flex md:grid md:grid-flow-col md:grid-rows-2 md:grid-cols-3 gap-4">
      <div className="py-2 flex-shrink-0 pr-2 md:pr-0">
        <img
          className=" min-w-fit md:min-w-0 rounded-lg shadow-lg md:w-full md:h-full object-cover"
          src="https://picsum.photos/500?random=1"
          alt=""
        />
      </div>
      <div className="py-2 flex-shrink-0 md:col-start-3 pr-2 md:pr-0">
        <img
          className="min-w-fit md:min-w-0 rounded-lg shadow-lg md:w-full md:h-full object-cover"
          src="https://picsum.photos/500?random=2"
          alt=""
        />
      </div>
      <div className="py-2 flex-shrink-0 pr-2 md:pr-0">
        <img
          className="min-w-fit md:min-w-0 rounded-lg shadow-lg md:w-full md:h-full object-cover"
          src="https://picsum.photos/500?random=3"
          alt=""
        />
      </div>
      <div className="py-2 flex-shrink-0 pr-2 md:pr-0">
        <img
          className="min-w-fit md:min-w-0 rounded-lg shadow-lg md:w-full md:h-full object-cover"
          src="https://picsum.photos/500?random=4"
          alt=""
        />
      </div>
      <div className="py-2 flex-shrink-0 md:col-span-2">
        <img
          className="min-w-fit md:min-w-0 rounded-lg shadow-lg md:w-full md:h-full object-cover"
          src="https://picsum.photos/1000/500?random=5"
          alt=""
        />
      </div>
    </div>
  </div>
);

export default Pictures;

import React from "react";

import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { images } from "../../Assets/img/image-data";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Slideshow = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [timer, setTimer] = useState(0);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    const interval = setInterval(() => setTimer((timer) => timer + 1), 5000);
    paginate(1);
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div className="h-screen relative flex items-center justify-center mb-6">
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          className="absolute object-cover w-full h-full md-max-h-full"
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial={{ opacity: 0 }}
          animate="center"
          exit={{ opacity: 0 }}
          transition={{
            x: { type: "spring", stiffness: 30, damping: 30 },
            opacity: { duration: 0.8 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <div
        className="absolute top-1/2 right-2.5 bg-white rounded-full w-10 h-10 flex items-center justify-center cursor-pointer select-none font-bold text-lg z-20"
        onClick={() => paginate(1)}
      >
        <ArrowForwardIosIcon />
      </div>
      <div
        className="absolute top-1/2 left-2.5 bg-white rounded-full w-10 h-10 flex items-center justify-center cursor-pointer select-none font-bold text-lg z-20"
        onClick={() => paginate(-1)}
      >
        <div className="pl-2 pb-1">
          <ArrowBackIosIcon />
        </div>
      </div>
    </div>
  );
};

export default Slideshow;

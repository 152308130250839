import { motion } from "framer-motion";
import personIcon from "../Assets/img/person.jpg";

import doctolib from "./../Assets/img/doctolib.svg";

const Card = ({ name, work, modalBody, link, delayView, handleModal }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, delay: delayView }}
      viewport={{ once: true }}
      className="p-4 lg:w-1/4 md:w-1/2 rounded shadow-md mx-10"
    >
      <div className="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          className="rounded-lg w-full object-cover object-center mb-4"
          src={personIcon}
        />
        <div className="w-full">
          <h2 className="font-bold text-3xl my-2">{name}</h2>
          <h3 className="text-gray-500 my-3">{work}</h3>
          <div
            onClick={() => handleModal(name, modalBody, personIcon)}
            className="mt-4 flex flex-col items-center justify-center font-bold text-xl duration-500 cursor-pointer p-4 rounded-full border border-slate-300 hover:border-sky-500 hover:text-sky-700"
          >
            <p>En savoir plus sur ma pratique</p>
            <p>et mon expérience</p>
          </div>
          <div className="mt-4 pt-6 flex items-center justify-center text-xl font-bold bg-gradient-to-r from-cyan-300 to-sky-500 hover:bg-sky-500 rounded-full px-3">
            <a
              href={link}
              rel="noreferrer"
              target="_blank"
              className="flex flex-col items-center justify-center text-white"
            >
              <span>Prendre un rendez-vous</span>
              <img className="h-16 xl:h-24" src={doctolib} alt="doctolib" />
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Card;

import PhoneIcon from "@mui/icons-material/Phone";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TrainOutlinedIcon from "@mui/icons-material/TrainOutlined";
import AccessibleIcon from "@mui/icons-material/Accessible";
import metro3 from "../../Assets/img/metro3.svg";
import rerc from "../../Assets/img/rerc.svg";
import bus84 from "../../Assets/img/bus84.svg";

const Feature = ({ name }) => {
  return (
    <div className="md:w-1/2 xl:w-1/4 px-6 flex">
      <div className="flex flex-col mx-auto w-60 items-center px-6 py-10 border-2 border-dashed border-sky-700 rounded-lg mt-12">
        <div className="flex  justify-center text-xl xl:text-2xl items-center my-2">
          {name === "Téléphone" && (
            <PhoneIcon className="text-sky-500" fontSize="large" />
          )}
          {name === "Horaires" && (
            <ScheduleIcon className="text-sky-500" fontSize="large" />
          )}
          {name === "Transports" && (
            <TrainOutlinedIcon className="text-sky-500" fontSize="large" />
          )}
          {name === "Accès" && (
            <AccessibleIcon className="text-sky-500" fontSize="large" />
          )}

          <h2 className="font-bold leading-none ml-2">{name} :</h2>
        </div>

        <span className="mt-6 text-center font-bold leading-normal text-xl text-sky-700">
          {name === "Téléphone" && <p className="text-2xl">06 66 43 95 12</p>}
          {name === "Horaires" && (
            <p>
              Ouvert du Lundi au Vendredi de{" "}
              <span className="text-gray-600">08h30</span> à{" "}
              <span className="text-gray-600">19h00</span>.
            </p>
          )}
          {name === "Transports" && (
            <div className="flex flex-col leading-none">
              <p className="flex items-center justify-center ">
                Métro
                <img
                  className="h-7 max-w-full ml-2"
                  src={metro3}
                  alt="metro 3"
                />
              </p>
              <span className="text-lg text-gray-600">
                <p>&nbsp;Station Pereire</p>
              </span>

              <p className="flex items-center justify-center">
                RER{" "}
                <img className="h-7 max-w-full ml-2" src={rerc} alt="ligne C" />
              </p>
              <span className="text-lg text-gray-600">
                <p>&nbsp;Pereire Levallois</p>
              </span>
              <p className="flex items-center justify-center">
                Bus{" "}
                <img
                  className="h-6 max-w-full ml-2"
                  src={bus84}
                  alt="ligne bus 84"
                />
              </p>

              <span className="text-lg text-gray-600">
                <p>&nbsp;Pereire - Marechal Juin</p>
              </span>
            </div>
          )}
          {name === "Accès" &&
            "Le cabinet est accessible en Rez-de-chaussée. Les fauteuils peuvent accéder au cabinet."}
        </span>
      </div>
    </div>
  );
};

export default Feature;

import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [sent, setSent] = useState(false);
  const [templateParams, setTemplateParams] = useState({
    user_name: "",
    message: "",
    user_email: "",
  });
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;

    setTemplateParams((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  if (
    templateParams.user_name &&
    templateParams.user_name &&
    templateParams.user_name
  ) {
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      templateParams.user_name &&
      templateParams.user_name &&
      templateParams.user_name
    ) {
      //  * Send a template to the specific EmailJS service
      //  * @param {string} serviceID - the EmailJS service ID
      //  * @param {string} templateID - the EmailJS template ID
      //  * @param {object} templatePrams - the template params, what will be set to the EmailJS template
      //  * @param {string} userID - the EmailJS user ID

      emailjs.send(
        "service_xjjlh0j",
        "contact_form",
        templateParams,
        "wqG8LBc1pz5JtUU0G"
      );

      setSent(true);
    } else {
      if (!templateParams.user_name) {
        setErrorName("Merci d'indiquer votre nom.");
      }
      if (!templateParams.user_email) {
        setErrorEmail("Merci d'indiquer votre email.");
      }
      if (!templateParams.message) {
        setErrorMessage("Merci de préciser votre demande.");
      }
    }
  };

  console.log(sent);

  return (
    <form
      onSubmit={sendEmail}
      className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
    >
      <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
        Nous envoyer un message :
      </h2>
      <p className="leading-relaxed mb-5 text-gray-600">
        Vous souhaitez nous faire part d'une question ou d'une demande,
        n'hésitez pas, nous pourrons vous répondre par e-mail.
      </p>
      <div className="relative mb-4">
        <label htmlFor="name" className="leading-7 text-sm text-gray-600">
          Nom et prénom
        </label>
        <input
          placeholder={errorName}
          type="text"
          id="name"
          name="user_name"
          onChange={handleChange}
          value={templateParams.user_name}
          className="w-full placeholder:italic placeholder:text-red-400 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="relative mb-4">
        <label htmlFor="email" className="leading-7 text-sm text-gray-600">
          Email
        </label>
        <input
          type="email"
          placeholder={errorEmail}
          id="email"
          name="user_email"
          onChange={handleChange}
          value={templateParams.user_email}
          className="w-full placeholder:italic placeholder:text-red-400 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="relative mb-4">
        <label htmlFor="message" className="leading-7 text-sm text-gray-600">
          Message
        </label>
        <textarea
          placeholder={errorMessage}
          id="message"
          name="message"
          onChange={handleChange}
          value={templateParams.message}
          className="w-full placeholder:italic placeholder:text-red-400 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
        ></textarea>
      </div>
      <button
        className={`text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg 
          disabled:opacity-75`}
        disabled={sent ? true : false}
      >
        {sent ? "Merci pour votre message" : "Envoyer"}
      </button>
    </form>
  );
};

export default ContactForm;

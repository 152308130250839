import { useRef, useState } from "react";

import "./App.css";
import Header from "./Components/Header";
import Section from "./Components/Section";
import Contact from "./Components/Sections/Contact";
import Pictures from "./Components/Sections/Pictures";
import Team from "./Components/Sections/Team";
import Footer from "./Components/Sections/Footer";
import ModalMleg from "./Components/ModalMleg";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState("");
  const [image, setImage] = useState({});
  const [body, setBody] = useState([]);

  const modal = useRef();
  //creates a reference to hold the element.
  const handleModal = (h, b, i) => {
    setHeader(h);
    setBody(b);
    setImage(i);
    setIsOpen(true);
  };

  return (
    <div>
      <Header />
      <Section id={"1"}>
        <Team handleModal={handleModal} />
      </Section>
      <Section id={"2"} className={"overflow-auto"}>
        <Pictures />
      </Section>
      <Section id={"3"}>
        <Contact />
      </Section>
      <Section className={"bg-gray-900 text-gray-100 -mb-8"}>
        <Footer handleModal={handleModal} />
      </Section>
      <ModalMleg
        ref={modal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        header={header}
        body={body}
        image={image}
      />
    </div>
  );
}

export default App;

import NavItem from "./NavItem";
import doctolib from "../Assets/img/bluedoctolib.png";

const Navbar = () => {
  return (
    <nav className="flex flex-col sm:items-center p-6 max-w-7xl mx-auto">
      <ul className="sm:flex sm:space-x-6 transition hover:duration-300">
        <NavItem to={"#Notre-équipe"} title={"Les kinésithérapeutes"} />
        <NavItem to={"#2"} title={"Le cabinet"} />
        <NavItem to={"#3"} title={"Nous trouver"} />
        <NavItem to={"#nous-contacter"} title={"Nous contacter"} />
        <li className="flex items-center hover:scale-125 transition ease-in-out delay-150 duration-300">
          <a
            href="www.doctolib.fr"
            className="bg-white px-4 py-2 rounded outline-none"
          >
            <img className="max-h-7" src={doctolib} alt="doctolib" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

import React from "react";

const Section = ({ children, className, id }) => {
  return (
    <div id={`${id}`} className={`${className} relative`}>
      {children}
    </div>
  );
};

export default Section;

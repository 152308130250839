import ContactForm from "./ContactForm";
import Feature from "./Feature";

const Contact = () => {
  return (
    <div className="flex flex-col">
      <div className="container px-5 pt-24 pb-6 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-white rounded-lg overflow-hidden sm:mr-10 p-12 flex items-end justify-start relative">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.633464631125!2d2.297132515675293!3d48.88426357929006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f96577ef46b%3A0xe62890ff6944dad0!2s97%20Rue%20de%20Prony%2C%2075017%20Paris!5e0!3m2!1sfr!2sfr!4v1647680880406!5m2!1sfr!2sfr"
            title="map"
            className="absolute inset-0"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{
              border: "0",
              filter: "grayscale(0.5)",
            }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <div className="bg-white relative invisible sm:flex flex-wrap mx-auto py-6 rounded shadow-md sm:visible">
            <div className="px-6">
              <h2 className="font-bold text-xl text-gray-600">Adresse :</h2>
              <p className="mt-1">
                <b>Cabinet de kinésithérapie</b>, 97 Rue de Prony, 75017 Paris
              </p>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>

      {/* FEATURES */}

      <div className="container mx-auto px-5 text-gray-600">
        <div
          id="nous-contacter"
          className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap justify-center max-w-screen-xl mx-auto py-20 pt-0 md:py-24"
        >
          <Feature name="Téléphone" />
          <Feature name="Horaires" />
          <Feature name="Transports" />
          <Feature name="Accès" />
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useEffect, forwardRef, useCallback } from "react";

import { motion, AnimatePresence } from "framer-motion";

const ModalMleg = forwardRef(
  ({ isOpen, setIsOpen, header, image, physio, body }, ref) => {
    const close = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleEscape = useCallback(
      (event) => {
        if (event.keyCode === 27) setIsOpen(false);
      },
      [setIsOpen]
    );

    useEffect(() => {
      if (isOpen) document.addEventListener("keydown", handleEscape, false);
      return () => {
        document.removeEventListener("keydown", handleEscape, false);
      };
    }, [handleEscape, isOpen]);

    const dropIn = {
      hidden: {
        y: "-100vh",
        opacity: 0,
      },
      visible: {
        y: "0",
        opacity: 1,
        transition: {
          duration: 1,
          type: "spring",
          // damping: 25,
          // stiffness: 500,
        },
      },
      exit: {
        transition: { duration: 1 },
        y: "100vh",
        opacity: 0,
      },
    };

    return (
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {isOpen && (
          <motion.div
            key="modal"
            // initial={{ opacity: 0 }}
            // whileInView={{ x: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            // transition={{ duration: 1 }}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            ref={ref}
            className="justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            {/* wrapper */}
            <div
              className="w-full h-full absolute top-0 right-0 bg-gray-700/60"
              onClick={close}
            />
            {/* content */}
            <div className="relative flex flex-col overflow-hidden mx-auto my-6 max-w-screen-lg border-0 rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t font-semibold text-3xl text-slate-700 bg-gradient-to-r from-sky-500 to-cian-500">
                <img
                  className="absolute -left-6 -top-4 w-32 h-32 rounded-full shadow-lg"
                  src={image}
                  alt={physio}
                />
                <h3 className="pl-28 text-white">{header}</h3>
                <h1 role="button" aria-label="close" onClick={close}>
                  X
                </h1>
              </div>
              {/* body */}
              <div className="relative p-6 flex-auto">
                {body &&
                  body.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="my-4 text-slate-500 text-lg leading-relaxed"
                      >
                        {e}
                      </p>
                    );
                  })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

export default ModalMleg;

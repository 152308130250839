import React from "react";
import { motion } from "framer-motion";

import Card from "../Card";

const Team = ({ handleModal }) => {
  const modalBody = [
    "De l'enfance à la fin de vie, le kinésithérapeute en périnéologie vous aidera à préserver votre périnée et ses fonctions associées: urinaires, gynécologiques, sexuelles et anorectales. Il interviendra dans le but thérapeutique de rééduquer votre fonction périnéale au sein d'une globalité corporelle pour lui rendre toute son efficacité et éliminer les gênes occasionnées qui grèvent votre vie sociale, et votre intimité. Le kinésithérapeute en périnéologie est le spécialiste de la rééducation périnéale à tout moment de la vie : prévention des troubles périnéaux, réhabilitation en post partum, fuites, prolapsus, constipation, douleurs pelvi périnéales, troubles sexuels, etc. Parlez lui en !",
  ];

  return (
    <section className="text-gray-600 body-font">
      <div
        className="container px-5 py-24 mx-auto "
        id="Notre-équipe"
        // style={{ maskImage: "linear-gradient(transparent, black)" }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          viewport={{ once: true }}
          className="flex flex-col text-center w-full mb-20"
        >
          <h1 className="text-2xl font-medium title-font mb-4 text-gray-900">
            NOTRE &Eacute;QUIPE
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them.
          </p>
        </motion.div>
        <div className="flex justify-center flex-wrap">
          <Card
            modalBody={modalBody}
            handleModal={handleModal}
            delayView={0.2}
            name="Martine Loobuyck"
            work="Masseur-Kinésithérapeute"
            link="https://www.doctolib.fr/masseur-kinesitherapeute/paris/martine-loobuyck"
          />
          <Card
            handleModal={handleModal}
            delayView={0.4}
            name="Antoine Demeulemester"
            work="Masseur-Kinésithérapeute"
            link="https://www.doctolib.fr/masseur-kinesitherapeute/paris/antoine-demeulemester"
          />
          <Card
            handleModal={handleModal}
            delayView={0.6}
            name="Antoine Demeulemester"
            work="Masseur-Kinésithérapeute"
            link="https://www.doctolib.fr/masseur-kinesitherapeute/paris/antoine-demeulemester"
          />
          <Card
            handleModal={handleModal}
            delayView={0.3}
            name="Antoine Demeulemester"
            work="Masseur-Kinésithérapeute"
            link="https://www.doctolib.fr/masseur-kinesitherapeute/paris/antoine-demeulemester"
          />
        </div>
      </div>
    </section>
  );
};

export default Team;
